'use client';

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from 'react';

import DemoAlert from '@/components/Layout/DemoAlert';
import Drawer from '@/components/Layout/Drawer';
import Footer from '@/components/Layout/Footer';
import { useNavigationEvent } from '@/hooks/useNavigationEvent';

import MaxMdOnly from 'src/components/Layout/MaxMdOnly';

export const LayoutContext = createContext<{
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}>({
  setIsDrawerOpen: () => null,
});

function Layout(props: { children?: ReactNode }) {
  const { children } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useNavigationEvent(() => {
    setIsDrawerOpen(false);
  });

  const value = useMemo(
    () => ({
      setIsDrawerOpen,
    }),
    [],
  );

  return (
    <LayoutContext.Provider value={value}>
      <main className="relative flex min-h-screen w-full flex-col">
        <DemoAlert />

        <div className="relative flex min-h-screen w-full grow flex-col">
          {children}
        </div>
        <Footer />
        <MaxMdOnly>
          <Drawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
            <Drawer.Content />
          </Drawer>
        </MaxMdOnly>
      </main>
    </LayoutContext.Provider>
  );
}

export default Layout;
