import { useAuth } from '@/hooks/useAuth';

export default function DemoAlert() {
  const { me, logout } = useAuth();

  if (me?.isDemo) {
    return (
      <div className="inset-x-0 top-0 z-10">
        <div className="flex h-102 w-full flex-col justify-center bg-orange-200 max-md:px-32 md:h-50 md:w-full md:flex-row md:items-center md:gap-16">
          <span className="body-2-r text-gray-500 max-md:w-294">
            This page is for demo purposes only. For actual product
          </span>
          <button onClick={logout}>
            <span className="header-4-m flex text-gray-500 underline max-md:w-full max-md:py-6">
              Click Here
            </span>
          </button>
        </div>
      </div>
    );
  }

  return null;
}
