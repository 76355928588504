import { UilFacebookF, UilInstagram } from '@iconscout/react-unicons';
import Image from 'next/image';
import Link from 'next/link';

import logo from '@/assets/home/logo.png';
import { InternalLink } from '@/hooks/useInternalRouter';

import MaxMdOnly from 'src/components/Layout/MaxMdOnly';
import MdOnly from 'src/components/Layout/MdOnly';

function Footer() {
  return (
    <div className="relative mt-124 flex w-full flex-col gap-32 px-24 py-40 max-md:rounded-t-20 max-md:bg-white md:mt-240 md:items-center md:gap-40 md:px-24 md:py-40">
      <InternalLink href="/" className="contents">
        <MaxMdOnly>
          <div className="h-24">
            <Image src={logo} width={97} height={24} alt="logo" />
          </div>
        </MaxMdOnly>
        <MdOnly>
          <div className="h-28">
            <Image src={logo} width={114} height={28} alt="logo" />
          </div>
        </MdOnly>
      </InternalLink>

      <div className="flex w-full flex-wrap items-center gap-16 md:justify-center md:gap-32">
        <Link href="https://www.gigfinesse.com/about/company" passHref>
          <span className="body-2-r text-gray-50">About</span>
        </Link>
        <div className="h-16 w-1 bg-gray-500" />
        <Link href="https://www.gigfinesse.com/about/careers" passHref>
          <span className="body-2-r text-gray-50">Careers</span>
        </Link>
        <div className="h-16 w-1 bg-gray-500" />
        <Link href="http://support.gigfinesse.com/" passHref>
          <span className="body-2-r text-gray-50">Support</span>
        </Link>
        <div className="h-16 w-1 bg-gray-500" />
        <Link href="https://www.gigfinesse.com/about/contact" passHref>
          <span className="body-2-r text-gray-50">Contact</span>
        </Link>
        <div className="h-16 w-1 bg-gray-500" />

        <Link href="https://www.gigfinesse.com/about/terms" passHref>
          <span className="body-2-r text-gray-50">Terms</span>
        </Link>
        <div className="h-16 w-1 bg-gray-500" />
        <Link href="https://www.gigfinesse.com/about/privacy" passHref>
          <span className="body-2-r text-gray-50">Privacy</span>
        </Link>
        <MdOnly>
          <div />
          <div className="flex gap-24">
            <Link href="https://www.facebook.com/gigfinesse">
              <div className="flex-center size-44 rounded-full bg-gray-500">
                <UilFacebookF className="size-24 fill-gray-25" />
              </div>
            </Link>
            <Link href="https://www.instagram.com/gigfinesse">
              <div className="flex-center size-44 rounded-full bg-gray-500">
                <UilInstagram className="size-24 fill-gray-25" />
              </div>
            </Link>
          </div>
        </MdOnly>
      </div>
      <div className="flex w-full flex-col max-md:gap-16 md:items-center">
        <span className="body-3-r text-gray-100">
          ©2024 GigFinesse, Inc. All rights reserved.
        </span>

        <MaxMdOnly>
          <div className="flex gap-16">
            <Link href="https://www.facebook.com/gigfinesse">
              <div className="flex-center size-44 rounded-full bg-gray-500">
                <UilFacebookF className="size-24 fill-gray-25" />
              </div>
            </Link>
            <Link href="https://www.instagram.com/gigfinesse">
              <div className="flex-center size-44 rounded-full bg-gray-500">
                <UilInstagram className="size-24 fill-gray-25" />
              </div>
            </Link>
          </div>
        </MaxMdOnly>
      </div>
    </div>
  );
}

export default Footer;
