import { UilAngleRight, UilExclamationCircle } from '@iconscout/react-unicons';

function SetupIncomplete() {
  return (
    <button className="flex w-full items-center justify-center gap-10 rounded-12 bg-gray-10 px-16 py-10">
      <div className="flex items-center gap-6">
        <UilExclamationCircle className="size-20 fill-danger-red-100" />
        <span className="header-5-m text-white">Setup Incomplete</span>
      </div>

      <UilAngleRight className="size-24 fill-gray-300" />
    </button>
  );
}

export default SetupIncomplete;
